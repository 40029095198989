
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { EditPen } from "@element-plus/icons-vue";
import { event } from "vue-gtag";

interface IStudent {
  _id: string;
  BirthDate: string;
  firstName: string;
  lastName: string;
  gender: string;
}

const appreciationActive = ref(false);
const appType = ref("");
const markActive = ref(false);
const markType = ref("");
export default defineComponent({
  name: "profile",
  components: {},
  props: {
    id: String,
  },
  setup(props) {
    const { t } = useI18n();
    const stats = ref({});
    const list = ref<IStudent[]>([]);

    function capitalize(s: string) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const generatePdf = async () => {
      event("Imp liste eleves", {
        event_category: "Impression PDF",
        event_label: "Class profile",
        value: 1,
      });

      let sy = window.localStorage.getItem("activeSchoolarYear");
      let match = {};
      match[`schoolarYearsHistory.${sy}`] = props.id;
      await ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: {
                $arrayElemAt: ["$classRoom._id", 0],
              },
              classRoomName: {
                $arrayElemAt: ["$classRoom.name", 0],
              },
              father: {
                $arrayElemAt: ["$parent.father", 0],
              },
              mother: {
                $arrayElemAt: ["$parent.mother", 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              adress: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          list.value = data;
          const doc = new jsPDF();
          let date = new Date();
          let titre = "Liste des élèves";

          doc.setFontSize(15);
          doc.text(titre, 85, 65);

          doc.setFont("Amiri");

          doc.setFontSize(10);
          doc.setFontSize(12);
          doc.text(date.toLocaleDateString("fr-FR"), 150, 40);
          let arr: string[] = [];
          let array: string[][] = [];
          for (const student of list.value) {
            arr.push(capitalize(student.firstName));
            arr.push(capitalize(student.lastName));

            array.push(arr);
            arr = [];
          }

          autoTable(doc, {
            theme: "grid",
            margin: { top: 70 },
            head: [["Nom", "prénom"]],
            styles: { font: "Amiri" },
            body: array,
            foot: [
              [
                {
                  colSpan: 1,
                  content: "Total",
                  styles: { halign: "center" },
                },
                {
                  colSpan: 1,
                  content: list.value.length,
                  styles: { halign: "center" },
                },
              ],
            ],
          });

          doc.save("Liste_des_élèves.pdf");
        })
        .catch((e) => console.log(e));
    };

    onMounted(async () => {
      await ApiService.get("/teacher/classroomstat/" + props.id)
        .then(({ data }) => {
          stats.value = data;
        })
        .catch((e) => console.log(e));
    });

    const resetActive = () => {
      appreciationActive.value = false;
      appType.value = "";
      markActive.value = false;
      markType.value = "";
    };

    function itemClick(value: string, on = "app") {
      if (on == "app") {
        appreciationActive.value = true;
        appType.value = value;
        markActive.value = false;
      } else {
        appreciationActive.value = false;
        markActive.value = true;
        markType.value = value;
      }
    }

    return {
      t,
      stats,
      generatePdf,
      EditPen,
      appreciationActive,
      markActive,
      resetActive,
      appType,
      markType,
      itemClick,
    };
  },
});
